<template>
  <div>
    <ClientOnly>
      <Notifications />
      <div
        v-if="drupalUser.accessToolbar && !isEditing"
        class="bg-white border-b border-b-gray-100 hidden lg:block"
      >
        <VuepalAdminToolbar :key="language" />
        <div class="flex">
          <div
            class="py-10 xl:min-w-[1174px] mx-auto w-auto flex justify-between items-center"
          >
            <VuepalLocalTasks />
            <div>
              <button @click="toggleDebug">Text Keys anzeigen</button>
            </div>
          </div>
        </div>
      </div>
    </ClientOnly>

    <AppTop @header-visibility-changed="updateHeaderVisible" />

    <NuxtPageDependency>
      <Breadcrumb :links="breadcrumb" />
    </NuxtPageDependency>

    <div class="page-content">
      <slot />
    </div>

    <ScrollTopButton :visible="!headerVisible" class="mobile-only:hidden" />
    <NuxtPageDependency>
      <Transition name="app-bottom">
        <AppBottom v-show="!isReduced" :header-visible="headerVisible" />
      </Transition>
    </NuxtPageDependency>

    <ClientOnly>
      <CookieBanner />
      <TermsOfServiceOverlay v-if="showTermsOfServiceModal" />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { useDrupalUser } from './../stores/drupalUser'

const route = useRoute()
const breadcrumb = useBreadcrumbLinks()
const drupalUser = useDrupalUser()
const language = useCurrentLanguage()
const site = useCurrentSite()

const { toggleDebug } = useEasyTexts()

const isReduced = computed(() => {
  if (route.meta.isReduced) {
    if (typeof route.meta.isReduced === 'function') {
      return route.meta.isReduced(route)
    }
  }
  return !!route.meta.isReduced
})

const isEditing = computed(
  () =>
    !!(route.query.blokkliEditing || route.query.blokkliPreview) &&
    drupalUser.canUseParagraphsBuilder,
)

const headerVisible = ref(true)
const updateHeaderVisible = (isVisible: boolean) => {
  headerVisible.value = isVisible
}

const showTermsOfServiceModal = computed(
  () =>
    site.name === 'pharmasuisse' &&
    drupalUser.isLoggedIn &&
    !drupalUser.termsOfServiceAccepted &&
    route.query['no-tos-check'] !== 'true',
)
</script>
