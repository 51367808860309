<template>
  <button
    class="inline-flex items-center text-green-alt hover:text-green group sticky bottom-20 -mb-40 left-full mr-50 cursor-pointer z-[9999] transition ease-in-out"
    :class="{
      'translate-y-full opacity-0': !visible,
    }"
    @click="scrollToTop"
  >
    <div
      class="flex items-center justify-center w-40 h-40 border-2 rounded overflow-hidden group-hover:bg-current transition"
    >
      <div class="relative w-15 h-15">
        <SpriteSymbol
          name="arrow"
          class="absolute top-0 left-0 w-full h-full -rotate-90 transition group-hover:-translate-y-30"
        />
        <SpriteSymbol
          name="arrow"
          class="absolute top-0 left-0 w-full h-full -rotate-90 transition translate-y-30 group-hover:translate-y-0 text-white"
        />
      </div>
    </div>
  </button>
</template>

<script lang="ts" setup>
defineProps<{
  visible: boolean
}>()

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}
</script>
