<template>
  <div class="flex uppercase gap-10 font-medium text-gray-500">
    <component
      v-bind="link.attrs"
      :is="link.isLink ? 'a' : 'span'"
      v-for="link in mappedLanguageLinks"
      :key="link.code"
      class="language-switch-link"
      :class="{
        'text-green-alt': link.active,
        'text-gray-100 cursor-not-allowed': !link.isLink,
        'hover:text-green-alt': !link.active && link.isLink,
      }"
      :title="link.label"
    >
      {{ link.code }}
    </component>
  </div>
</template>
<script lang="ts" setup>
const ALL = ['de', 'fr', 'it']

function getLanguageLabel(code: string) {
  if (code === 'de') {
    return 'Deutsch'
  } else if (code === 'fr') {
    return 'Francais'
  } else if (code === 'it') {
    return 'Italiano'
  }

  return code
}

const router = useRouter()
const route = useRoute()
const event = useRequestEvent()

/**
 * Detect whether we are rendering inside an island request.
 */
const isIsland = computed(() => {
  if (!event) {
    return false
  }
  if (!event.path) {
    return false
  }
  return (
    event.path.includes('nuxt_island') || event.path.includes('islands-page')
  )
})

// The computed language links. For Nuxt internal routes we can just return the
// new language param, as vue-router will use the current route.
const languageLinks = useLanguageLinks()
const site = useCurrentSite()
const mappedLanguageLinks = computed(() => {
  // Italian is not available on the FPHCH site.
  return ALL.filter((code) => site.name !== 'fphch' || code !== 'it').map(
    (code) => {
      const link = languageLinks.value.find((v: any) => v.code === code)
      // First resolve the provided language link.
      const resolved = link ? router.resolve(link.to).fullPath : undefined

      // Use the resolved link and again resolve it, this time using the
      // current route query params.
      let href = resolved
        ? router.resolve({ path: resolved, query: route.query }).fullPath
        : undefined

      // We replace the href of the language link with a known key that can be
      // replaced dynamically by the island consumer.
      if (isIsland.value) {
        href = '$LANGUAGE_LINK_' + code.toUpperCase() + '$'
      }

      return {
        active: !!link?.active,
        code,
        label: getLanguageLabel(code),
        isLink: !!link,
        attrs: {
          href: href || undefined,
        },
      }
    },
  )
})
</script>
