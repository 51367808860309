<template>
  <transition-group
    enter-active-class="duration-300 ease-out origin-top"
    enter-from-class="transform transform translate-y-full md:-translate-y-full opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-200 ease-in origin-top"
    leave-to-class="transform transform translate-y-full md:-translate-y-full opacity-0"
  >
    <Message
      v-for="notification in notifications.notifications"
      v-bind="notification"
      :key="notification.id"
      class="notification"
      @click="notifications.remove(notification.id)"
      @close="notifications.remove(notification.id)"
    />
  </transition-group>
</template>

<script lang="ts" setup>
import { useNotifications } from '~/stores/notifications'

const notifications = useNotifications()
</script>

<style lang="postcss">
.notification {
  @apply fixed bottom-0 md:top-0 md:bottom-auto w-full z-[5000000] max-h-100 overflow-auto;

  a {
    @apply underline;
  }
}
</style>
