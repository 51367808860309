<template>
  <BlokkliProvider v-bind="blokkli" isolate edit-label="Footer bearbeiten">
    <div
      class="layout-footer bg-white py-10 md:py-20 lg:py-40 mt-60 lg:mt-70 xl:mt-90"
    >
      <Container>
        <Logo class="w-120 lg:w-200" />
        <BlokkliField
          :list="paragraphs"
          name="field_footer_section_paragraphs"
          non-empty-class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 text-base mt-30"
        />
        <div
          class="border-t border-green mt-30 pt-30 grid gap-20 md:flex text-sm relative lg:gap-30 items-start"
        >
          <div class="text-gray-500 uppercase mr-auto">
            &copy; {{ copyright }}
          </div>
          <BlokkliField
            :list="metaParagraphs"
            name="field_footer_section_metalinks"
          />
          <PageFooterLanguageSwitcher />
        </div>
      </Container>
    </div>
  </BlokkliProvider>
</template>

<script setup lang="ts">
import { FooterConfigFragment } from '#graphql-operations'

defineOptions({
  name: 'PageFooter',
})

const props = defineProps<{
  uuid?: string
  canEdit?: boolean
  entityTypeId?: string
  entityBundle?: string
  paragraphs?: FooterConfigFragment['paragraphs']
  meta?: FooterConfigFragment['meta']
}>()

const { $texts } = useNuxtApp()

const copyright = computed(() => {
  const year = new Date().getFullYear().toString()
  return $texts('footer.copyright', '@year pharmaSuisse').replace('@year', year)
})

const metaParagraphs = computed(() => (props.meta ? [props.meta] : []))
const blokkli = buildBlokkliProps(props, 'translatable_config_pages', 'footer')
</script>

<style lang="postcss">
@import '~/assets/css/partials/footer.css';
/* Don't put any CSS in here! Put it in ~/assets/css/partials/footer.css instead. */
</style>
