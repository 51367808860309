<template>
  <div>
    <!-- <RenderCacheable v-if="footer" :cache-key="cacheKey" :max-age="600"> -->
    <PageFooter v-if="footer" v-bind="footer" />
    <!-- </RenderCacheable> -->
  </div>
</template>

<script lang="ts" setup>
const language = useCurrentLanguage()

const cacheKey = computed(() => {
  return 'footer_' + language.value
})

const { data: footer } = await useLazyAsyncData(
  cacheKey.value,
  () => useCachedGraphqlQuery('footerQuery').then((v) => v.data.footer),
  {
    watch: [language],
  },
)
</script>
