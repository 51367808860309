<template>
  <div class="relative">
    <div class="absolute left-0 w-full top-0">
      <Transition name="breadcrumb-slide">
        <section
          v-if="links.length"
          class="container px-20 mx-auto md:pt-10 lg:pt-20 text-small font-medium relative mobile-only:h-60 mobile-only:overflow-hidden"
        >
          <nav
            class="breadcrumb mobile-only:absolute mobile-only:top-0 mobile-only:left-0 mobile-only:w-full mobile-only:h-70"
            aria-label="breadcrumbs"
          >
            <ol
              ref="list"
              itemscope
              itemtype="http://schema.org/BreadcrumbList"
              class="flex whitespace-nowrap overflow-auto mobile-only:h-full items-center lg:overflow-hidden"
            >
              <li
                v-for="(link, index) in linksComputed"
                :key="index"
                itemprop="itemListElement"
                itemscope
                itemtype="http://schema.org/ListItem"
                class="mobile-only:last:pr-20 mobile-only:first:pl-20 mobile-only:mb-10 uppercase"
              >
                <component :is="link.tag" v-bind="link.props" itemprop="item">
                  <span itemprop="name">
                    {{ link.title }}
                  </span>
                </component>
                <meta itemprop="position" :content="`${index + 2}`" />
              </li>
            </ol>
          </nav>
        </section>
      </Transition>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { BreadcrumbFragment } from '#graphql-operations'
import type { PageLanguage } from '#language-negotiation/language'
import { NuxtLink } from '#components'

const list = ref<HTMLOListElement | null>(null)

const props = defineProps<{
  links: BreadcrumbFragment[]
  language?: PageLanguage
}>()

const linksComputed = computed(() => {
  return props.links.map((link) => {
    const { title, url } = link
    const to = url?.path
    return {
      tag: to ? NuxtLink : 'span',
      props: to ? { to } : {},
      title,
    }
  })
})

watch(linksComputed, () => {
  nextTick(() => {
    if (list.value && window.innerWidth < 768) {
      list.value.querySelector('li:last-child')?.scrollIntoView()
    }
  })
})
</script>

<style lang="postcss">
.breadcrumb {
  li {
    @apply text-gray-500;

    &:not(:first-child):before {
      content: '›';
      @apply mx-10;
    }
  }

  a {
    @apply text-gray-700 hover:text-gray-900;
  }
}
.breadcrumb-slide-enter-active,
.breadcrumb-slide-leave-active {
  transition: all 0.4s;
}
.breadcrumb-slide-enter-from,
.breadcrumb-slide-leave-to {
  opacity: 0;
  transform: translateY(-90%);
}
</style>
