<template>
  <div
    class="relative pl-20 pr-50 py-10 font-medium text-md text-left"
    :class="classes"
  >
    <div class="py-10" v-html="message" />
    <button
      v-if="!noClose"
      class="absolute top-10 right-0 p-15"
      @click="emit('close')"
    >
      <SpriteSymbol name="close" class="w-20 h-20 fill-current" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { NotificationType } from '~/stores/notifications'

const emit = defineEmits(['close'])

const props = defineProps<{
  type?: NotificationType
  duration?: number
  message: string
  isError?: boolean
  noClose?: boolean
}>()

const classes = computed(() => {
  if (props.type === NotificationType.Error || props.isError) {
    return 'bg-red-light text-red'
  } else if (props.type === NotificationType.Success) {
    return 'bg-green-lighter text-green'
  }
})
</script>
